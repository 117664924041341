import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import useInternetState from './useInternetState';

const OfflineContext = createContext({ offline: null });
const OfflineProvider = (props) => {
  const { children, functions, funcName, url } = props;
  const { online } = useInternetState({ functions, funcName, url });

  return (
    <OfflineContext.Provider value={online}>{children}</OfflineContext.Provider>
  );
};

OfflineProvider.propTypes = {
  children: PropTypes.objectOf(PropTypes.any)
};
OfflineProvider.defaultProps = {
  children: []
};
export { OfflineProvider, OfflineContext };
